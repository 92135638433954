import { TTrackedChangeDiff } from "./APITypes";
import { Maybe } from "./Types";
import { FileCreationResponse, File as PincitesFile } from "@pincites/api-client";

export enum InclusionRequirement {
  REQUIRED = "Required",
  OPTIONAL = "Optional",
  LEGACY_PROHIBITED = "Prohibited",
}

export enum PlaybookHistoryChangeType {
  // matching the values from the API
  PLAYBOOK_CHANGED = "playbook_changed",
  PLAYBOOK_CREATED = "playbook_created",
  CLAUSE_CHANGED = "clause_changed",
  CLAUSE_DELETED = "clause_deleted",
  CLAUSE_ADDED = "clause_added",
  ALTERNATIVE_LANGUAGE_CHANGED = "alternative_language_changed",
  ALTERNATIVE_LANGUAGE_DELETED = "alternative_language_deleted",
  ALTERNATIVE_LANGUAGE_ADDED = "alternative_language_added",
  CHECK_CHANGED = "check_changed",
  CHECK_DELETED = "check_deleted",
  CHECK_REMOVED = "check_removed",
  CHECK_ADDED = "check_added",
  CHECK_SCOPE_CHANGED = "check_scope_changed",
}

export enum PlaybookLevelType {
  PLAYBOOK = "playbook",
  CLAUSES = "clauses",
  CHECKS = "checks",
  CONTRACTS = "contracts",
}

export enum UserRole {
  ADMIN = "admin",
  CONTRACT_REVIEWER = "contract_reviewer",
}

export enum CheckScope {
  ALL_CONTRACTS = "all_contracts",
  FIRST_PARTY_PAPER = "first_party_paper",
  THIRD_PARTY_PAPER = "third_party_paper",
}

export enum CheckTestRunState {
  RUNNING = "running",
  COMPLETED = "completed",
}

export enum CheckTestRunResultState {
  pending = "pending",
  generating_suggestion = "generating_suggestion",
  completed = "completed",
  errored = "errored",
}

export enum CheckTestRunResult {
  PASSED = "passed",
  FAILED = "failed",
  LOADING = "loading",
  UNKNOWN = "unknown",
  ERROR = "error",
  NOT_RUN = "not_run",
}

export type TApiErrorResponse = {
  errors: [
    {
      detail: string;
      source?: {
        pointer: string;
      };
    },
  ];
};

type TSaved = {
  id: string;
};

export function isSaved(arg: unknown): arg is TSaved {
  return !!arg && typeof arg === "object" && "id" in arg && typeof arg.id === "string";
}

export type TClauseTemplate = {
  id: Maybe<string>;
  playbookID: string;
  name: string;
  inclusion_requirement: InclusionRequirement;
  text: string;
  notes: string;
};
export type TSavedClauseTemplate = TClauseTemplate & TSaved;

/**
 * A check that is not scoped to a playbook. Currently only used for Pincites managed
 * checks in the check library.
 */
export type TUnscopedCheck = {
  id: Maybe<string>;
  name: string;
  text: string;
  notes: string;
  resolutionGuidance: string;
  commentsEnabled: boolean;
  commentGuidance: string;
  createdAt: string;
  updatedAt: string;
  readonly isPincitesManaged: boolean;
};
export type TSavedUnscopedCheck = TUnscopedCheck & TSaved;

/**
 * A check that is scoped to a playbook. Alongside the base check properties it has a
 * playbookID, scope, and may have a clauseTemplateID.
 *
 * May be Pincites managed or custom.
 */
export type TPlaybookScopedCheck = TUnscopedCheck & {
  scope: CheckScope;
  playbookID: string;
  clauseTemplateID?: Maybe<string>;
};
export type TSavedPlaybookScopedCheck = TPlaybookScopedCheck & TSaved;
export function isPlaybookCheck(check: TCheck): check is TPlaybookScopedCheck {
  return "scope" in check && check.scope != null;
}

/**
 * A check that is either an unscoped check or a playbook check.
 */
export type TCheck = TUnscopedCheck | TPlaybookScopedCheck;
export type TSavedCheck = TCheck & TSaved;

export type TSavedClauseTemplateCheck = TSavedPlaybookScopedCheck & { clauseTemplateID: string };
export function isSavedClauseTemplateCheck(check: TCheck): check is TSavedClauseTemplateCheck {
  if (!isPlaybookCheck(check)) return false;
  if (!isSaved(check)) return false;
  return typeof check.clauseTemplateID === "string";
}

export type TPlaybookEditHistory = {
  changeType: PlaybookHistoryChangeType;
  clauseName: string;
  clauseTemplateID: string;
  clauseTextAlternativeID: string;
  checkName: string;
  checkID: string;
  user: TUser;
  createdAt: string;
  changedFields: {
    fieldName: string;
    oldValue: string;
    newValue: string;
  }[];
};

export type TManagedCheckSuite = {
  id: Maybe<string>;
  name: string;
  description: string;
  publishedAt?: Maybe<string>;
};

export type TSavedManagedCheckSuite = TManagedCheckSuite & TSaved;

// This type definition looks weird, but it is intentional to call out the ambigious state of File typing.
export type TPlaybookFile = Omit<PincitesFile, "status" | "signedUrl" | "playbookId"> &
  Pick<FileCreationResponse, "status" | "signedUploadUrl"> & { signedUrl: string; playbookId: string };

export type TUser = {
  id: string;
  microsoftID: string;
  name: string;
  email: string;
  role: Maybe<UserRole>;
  createdAt: string;
  updatedAt: string;
};

export type TInvite = {
  id: string;
  email: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
};

export function isUserWithRole(arg: unknown): arg is TUser {
  return (
    !!arg &&
    typeof arg === "object" &&
    "id" in arg &&
    typeof arg.id === "string" &&
    "microsoftID" in arg &&
    typeof arg.microsoftID === "string" &&
    "name" in arg &&
    typeof arg.name === "string" &&
    "email" in arg &&
    typeof arg.email === "string" &&
    "role" in arg &&
    Object.values(UserRole).includes(arg.role as UserRole) &&
    "createdAt" in arg &&
    typeof arg.createdAt === "string" &&
    "updatedAt" in arg &&
    typeof arg.updatedAt === "string"
  );
}

export type TPlaybook = {
  id: Maybe<string>;
  name: string;
  description: string;
  updatedAt: Maybe<string>;
  createdBy: TUser;
};

export type TSavedPlaybook = TPlaybook & TSaved;

export type TExampleSuggestion = {
  id: string;
  checkID: string;
  originalText: string;
  suggestedText: string;
  suggestedComment: string;
  createdAt: string;
  createdBy: TUser;
};

export type TCheckTestRun = {
  id: string;
  state: CheckTestRunState;
  results: TCheckTestRunResult[];
  createdAt: string;
  updatedAt: string;
};

export type TCheckTestRunResult = {
  file: TPlaybookFile;
  checkTestRunID: string;
  state: CheckTestRunResultState;
  result: CheckTestRunResult | null;
  explanation: string | null;
  sources: string[] | null;
  suggestions: TSuggestion[] | null;
  errorGeneratingSuggestions: string | null;
  matchedClause: TTrackedChangeDiff[] | null;
};

export type TSuggestion = {
  id: string;
  originalText: string;
  suggestedText: string;
  suggestedComment: string;
};

export type TClauseCheckResultBenchmarkExample = {
  checkText: string;
  exampleName: string;
  clauseTemplateID: string;
  documentClauseText: string;
  expectedResult: CheckTestRunResult;
  expectedSources: string[];
  evaluationNotes: string;
};

export type TContractCheckResultBenchmarkExample = {
  testContractID: string;
  checkText: string;
  exampleName: string;
  expectedResult: CheckTestRunResult;
  expectedSources: string[];
  evaluationNotes: string;
};

export type TContractCheckFixBenchmarkExample = {
  testContractID: string;
  checkText: string;
  exampleName: string;
  checkResolutionGuidance: string;
  checkCommentGuidance: string;
  checkFailureReason: string;
  checkSourceClauses: string[];
  expectedSuggestionContent: string[];
  expectedCommentContent: string[];
  expectedSuggestionContentOmissions: string[];
  expectedCommentContentOmissions: string[];
};
